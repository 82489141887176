<template>
  <!--card-form-->
  <div id="transaction-config-card" class="card cardlabel sticky-top align-top">
    <div class="expander" @click="isExpand = !isExpand">
      <h2>
        <span style="color: #003cff">{{ contract.get("symbol") }}</span> -
        Configuration
      </h2>
      <font-awesome-icon icon="fa-solid fa-chevron-down" :class="{ 'icon-rotate': isExpand === true }" />
    </div>
    <div v-if="isCopyTrading()" class="card-body" :class="{ 'is-expand': isExpand === true }">
      <div class="d-flex flex-wrap">
        <div class="col-lg-6 pe-lg-3">
          <div class="d-flex">
            <div class="" style="margin-right: 8px; margin-top: 3px">
              <h2 class="m-0">Buy</h2>
            </div>
          </div>
          <div class="d-flex flex-wrap mt-2">
            <div class="col-lg-6 pe-lg-3 col-sm-12 col-12">
              <div class="">
                <label class="form-label">{{ getNetwork().currency }} Amount<img class="ms-lg-2 pointer"
                    src="img/info-card.svg" onclick="Intercom('showArticle', 6208171)" /></label>
                <input v-model="config.buyAmount" type="text" class="form-control" placeholder="12" />
              </div>
            </div>
            <div class="col-lg-6 ps-lg-3 col-sm-12 col-12 mobile-mt">
              <div class="d-flex align-items-center dm">
                <input v-model="config.isBuyInstant" id="check" class="form-check-input" type="checkbox" value=""
                  aria-label="..." />
                <label class="me-2 m-0 ps-3" for="check">Buy Instant</label>
                <img class="ms-lg-2 pointer" src="img/info-card.svg" onclick="Intercom('showArticle', 6208167)" />
              </div>
              <div class="d-flex align-items-center dm mt-1">
                <input v-model="config.isBuySameAmount" id="check2" class="form-check-input" type="checkbox" value=""
                  aria-label="..." />
                <label class="me-2 m-0 ps-3" for="check2">Same Amount</label>
                <img class="ms-lg-2 pointer" src="img/info-card.svg" onclick="Intercom('showArticle', 6208171)" />
              </div>
              <div class="d-flex align-items-center dm mt-1">
                <input v-model="config.isBuyOnce" id="check3" class="form-check-input" type="checkbox" value=""
                  aria-label="..." />
                <label class="me-2 m-0 ps-3" for="check3">Buy Once</label>
                <img class="ms-lg-2 pointer" src="img/info-card.svg" onclick="Intercom('showArticle', 6208176)" />
              </div>
            </div>
          </div>
          <div class="d-flex flex-wrap mt-2">
            <div class="col-lg-6 pe-3">
              <div class="">
                <label class="form-label">Normal Gas Multiplier<img class="ms-lg-2 pointer" src="img/info-card.svg"
                    onclick="Intercom('showArticle', 6208167)" /></label>
                <input v-model="config.buyGasMultiplier" type="text" class="form-control" placeholder="0" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="">
                <label class="form-label">Frontrun Gas Multiplier<img class="ms-lg-2 pointer" src="img/info-card.svg"
                    onclick="Intercom('showArticle', 6208167)" /></label>
                <input v-model="config.buyFastGasMultiplier" type="text" class="form-control" placeholder="1.5" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mobile-mt">
          <div class="d-flex" style="margin-top: 3px">
            <h2 class="m-0">Sell</h2>
          </div>
          <div class="d-flex flex-wrap mt-2">
            <div class="col-lg-12 pe-3">
              <div class="d-flex flex-wrap">
                <div class="col-lg-6 pe-3">
                  <div class="">
                    <label class="form-label">Threshold<img class="ms-lg-2 pointer" src="img/info-card.svg"
                        onclick="Intercom('showArticle', 6208182)" /></label>
                    <input v-model="config.sellThreshold" type="text" class="form-control" placeholder="0" />
                  </div>
                </div>
                <div class="col-lg-6 ps-lg-3 col-sm-12 col-12 mobile-mt">
                  <div class="d-flex align-items-center dm">
                    <input v-model="config.isSellOnWarn" id="checksellinstant" class="form-check-input" type="checkbox"
                      value="" aria-label="..." />
                    <label class="me-2 m-0 ps-3" for="checksellinstant">Sell Instant</label>
                    <img class="ms-lg-2 pointer" src="img/info-card.svg" onclick="Intercom('showArticle', 6208184)" />
                  </div>
                  <div class="d-flex align-items-center dm mt-3">
                    <input v-model="config.isSellSameAmount" id="checksameamount" class="form-check-input"
                      type="checkbox" value="" aria-label="..." />
                    <label class="me-2 m-0 ps-3" for="checksameamount">Same Amount</label>
                    <img class="ms-lg-2 pointer" src="img/info-card.svg" onclick="Intercom('showArticle', 6208193)" />
                  </div>
                </div>
              </div>
              <div class="d-flex flex-wrap mt-2">
                <div class="col-lg-6 pe-3">
                  <div class="">
                    <label class="form-label">Normal Gas Multiplier<img class="ms-lg-2 pointer" src="img/info-card.svg"
                        onclick="Intercom('showArticle', 6208184)" /></label>
                    <input v-model="config.sellGasMultiplier" type="text" class="form-control" placeholder="0" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="">
                    <label class="form-label">Frontrun Gas Multiplier<img class="ms-lg-2 pointer"
                        src="img/info-card.svg" onclick="Intercom('showArticle', 6208184)" /></label>
                    <input v-model="config.sellFastGasMultiplier" type="text" class="form-control" placeholder="1.5" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="card-body" :class="{ 'is-expand': isExpand === true }">
      <div class="d-flex flex-wrap">
        <div class="col-lg-6 pe-lg-3">
          <div class="d-flex">
            <div class="" style="margin-right: 8px; margin-top: 3px">
              <h2 class="m-0">Buy</h2>
            </div>
            <div class="col-lg-5 d-flex align-items-center" :style="getLevel().canSnipe() ? '' : 'visibility: hidden;'">
              <label class="form-label" style="margin-right: 6px !important; margin-top: 6px !important">On
                <img id="help-buy-on" class="pointer ms-lg-2" src="img/info-card.svg" data-toggle="tooltip"
                  data-placement="bottom" title="Trigger buy on this function"
                  onclick="Intercom('showArticle', 6020973)" />
              </label>
              <input style="margin-top: 0px" v-model="config.buyOn" type="text" class="form-control" placeholder="" />
            </div>
            <a v-if="canWatch" @click="handleWatch" data-mdb-placement="bottom" title="Watch" style="margin-left: 10px">
              <img v-if="isWatch" style="width: 30px; height: 30px" class="action-icon" src="img/unobserve.svg" />
              <img v-else style="width: 30px; height: 30px" class="action-icon" src="img/observe.svg" />
              <span v-if="isWatch">Watching...</span>
            </a>
          </div>
          <div class="d-flex flex-wrap mt-2">
            <div class="col-lg-6 pe-lg-3 col-sm-12 col-12">
              <div class="">
                <label class="form-label">{{ getNetwork().currency }} Amount<img class="ms-lg-2 pointer"
                    src="img/info-card.svg" onclick="Intercom('showArticle', 6021003)" /></label>
                <input v-model="config.buyAmount" type="text" class="form-control" placeholder="12" />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="" v-if="!config.isOriginalRouter">
                <label class="form-label">Max Percent<img class="ms-lg-2 pointer" src="img/info-card.svg"
                    onclick="Intercom('showArticle', 6021003)" /></label>
                <input v-model="config.maxSupply" type="text" class="form-control" placeholder="0.75" />
              </div>
            </div>
          </div>
          <div v-if="getLevel().canSnipe()" class="d-flex flex-wrap mt-2">
            <div class="col-lg-6 pe-3">
              <div class="">
                <label class="form-label">Normal Gas Multiplier<img class="ms-lg-2 pointer" src="img/info-card.svg"
                    onclick="Intercom('showArticle', 6021015)" /></label>
                <input v-model="config.buyGasMultiplier" type="text" class="form-control" placeholder="0" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="">
                <label class="form-label">Gas Limit<img class="ms-lg-2 pointer" src="img/info-card.svg"
                    onclick="Intercom('showArticle', 6208153)" /></label>
                <input v-model="config.gasLimitETH" type="text" class="form-control" placeholder="1.5" />
              </div>
            </div>
          </div>
          <div class="d-flex flex-wrap mt-2">
            <div class="col-lg-6 pe-3">
              <div class="">
                <label class="form-label">Initial Pool ETH Amount</label>
                <input v-model="config.initialPoolETHAmount" type="text" class="form-control" placeholder="1" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="">
                <label class="form-label">Initial Pool Token Percent</label>
                <input v-model="config.initialPoolTokenPercent" type="text" class="form-control" placeholder="100" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mobile-mt">
          <div class="d-flex" style="height: 30px; margin-top: 3px">
            <h2 class="m-0">Sell</h2>
          </div>
          <div class="d-flex flex-wrap mt-2">
            <div class="col-lg-6 pe-lg-3 col-sm-12 col-12">
              <div class="">
                <label class="form-label">Account’s Percent<img class="ms-lg-2 pointer" src="img/info-card.svg"
                    onclick="Intercom('showArticle', 6021011)" /></label>
                <input v-model="config.sellPercent" type="text" class="form-control" placeholder="100" />
              </div>
            </div>
            <div class="col-lg-6 ps-lg-3 col-sm-12 col-12 mobile-mt" v-if="getLevel().canSnipe()">
              <div class="d-flex align-items-center dm">
                <input v-model="config.isBuyInstant" id="check" class="form-check-input" type="checkbox" value=""
                  aria-label="..." />
                <label class="me-2 m-0 ps-3" for="check">{{
                  config.isBuyInstant ? "Buy Instant" : "Blocks: "
                }}</label>
                <input v-if="!config.isBuyInstant" v-model="config.blocks" style="
                    width: 30px;
                    height: 30px;
                    margin-top: 0px;
                    margin-left: 5px;
                  " class="form-check-input" />
                <img class="ms-lg-2 pointer" src="img/info-card.svg" onclick="Intercom('showArticle', 6021015)" />
              </div>
              <div class="d-flex align-items-center dm mt-3">
                <input v-model="config.isSellOnWarn" id="check2" class="form-check-input" type="checkbox" value=""
                  aria-label="..." />
                <label class="me-2 m-0 ps-3" for="check2">Sell on Warn</label>
                <img class="ms-lg-2 pointer" src="img/info-card.svg" onclick="Intercom('showArticle', 6021020)" />
              </div>
            </div>
          </div>
          <div v-if="getLevel().canSnipe()" class="d-flex flex-wrap mt-2">
            <div class="col-lg-12 pe-3">
              <div class="d-flex flex-wrap">
                <div class="col-lg-6 pe-3">
                  <div class="">
                    <label class="form-label">Normal Gas Multiplier<img class="ms-lg-2 pointer" src="img/info-card.svg"
                        onclick="Intercom('showArticle', 6021020)" /></label>
                    <input v-model="config.sellGasMultiplier" type="text" class="form-control" placeholder="0" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="">
                    <label class="form-label">Frontrun Gas Multiplier<img class="ms-lg-2 pointer"
                        src="img/info-card.svg" onclick="Intercom('showArticle', 6021020)" /></label>
                    <input v-model="config.sellFastGasMultiplier" type="text" class="form-control" placeholder="1.5" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-wrap mt-2">
            <div class="col-lg-6">
                  <div class="d-flex mt-2 justify-content-center" style="flex-direction: column;">
                    <label class="form-label">Website</label>
                    <input style="" class="form-check-input" type="checkbox" :checked="config.isWebsite"
                      @click="toggleWebsite" aria-label="..." />
                  </div>
                </div>
          </div>
        </div>
      </div>

      <hr />
      <div class="d-flex flex-wrap align-items-center mobile-gap">
        <div class="col-lg-6">
          <div class="d-flex flex-wrap">
            <div class="col-lg-6">
              <h2 class="m-0">Cancel</h2>
            </div>
            <div class="col-lg-6 d-flex align-items-center">
              <label class="form-label" style="margin-right: 6px !important; margin-top: 3px !important">Gas
                Multiplier</label>
              <input style="margin-top: -5px" v-model="config.cancelGasMultiplier" type="text" class="form-control"
                placeholder="" />
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div v-if="true || getLevel().canSnipe()" class="d-flex flex-wrap pe-3">
            <div class="col-lg-6">
              <div class="button-text d-flex justify-content-center align-items-center" style="margin-top: -4px">
                <a style="font-size: 12px" @click="getSelector()" class="btn-theme">Get Selector</a>
                <img class="pointer ms-lg-2" src="img/info-card.svg" onclick="Intercom('showArticle', 6020973)" />
              </div>
            </div>
            <div class="col-6 d-flex align-items-center">
              <input style="margin-top: -5px" v-model="config.bundleTip" type="text" class="form-control"
                placeholder="" />
            </div>
          </div>
        </div>
      </div>
      <hr />

      <div class="d-flex justify-content-between" style="margin-right: 8px; margin-top: 3px; margin-bottom: 16px">
        <h2 class="m-0" style="margin-right: 20px">Flash Actions</h2>
        <div class="d-flex">
          <div class="button-text d-flex justify-content-center align-items-center" style="margin-top: -4px">
            <a v-if="!this.pending.mev" style="font-size: 12px; width: 80px" @click="onMev('mev')"
              class="btn-theme">MEV</a>
            <img v-else class="loading-icon" src="img/spinner.svg" />
          </div>
          <div class="d-flex align-items-center">
            <div style="margin-right: 12px; font-weight: 700">100% Sell:</div>
            <input class="form-check-input" type="checkbox" :checked="config.isPercent" @click="togglePercent"
              aria-label="..." />
          </div>
          <div class="button-text d-flex justify-content-center align-items-center" style="margin-top: -4px">
            <a v-if="!this.pending.mixtrade" style="font-size: 12px; width: 150px"
              @click="onLaunch('mixtrade', false, true)" class="btn-theme">MIXTRD</a>
            <img v-else class="loading-icon" src="img/spinner.svg" />
          </div>
          <div>
            <div class="button-text d-flex justify-content-center align-items-center" style="margin-top: -4px">
              <a v-if="!this.pending.multibuy" style="font-size: 12px; width: 150px"
                @click="onLaunch('multibuy', false)" class="btn-theme">MULTI BUY</a>
              <img v-else class="loading-icon" src="img/spinner.svg" />
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-lg-2 pe-lg-1">
          <label class="form-label">Tip<img class="ms-lg-2 pointer" src="img/info-card.svg"
              onclick="Intercom('showArticle', 6021003)" /></label>
          <input style="margin-top: 0px" v-model="config.tipAmount" type="text" class="form-control" placeholder="" />
        </div>
        <div class="col-lg-2 pe-lg-1">
          <label class="form-label">Min<img class="ms-lg-2 pointer" src="img/info-card.svg"
              onclick="Intercom('showArticle', 6021003)" /></label>
          <input style="margin-top: 0px" v-model="config.minAmount" type="text" class="form-control" placeholder="" />
        </div>
        <div class="col-lg-2 pe-lg-1">
          <label class="form-label">Max<img class="ms-lg-2 pointer" src="img/info-card.svg"
              onclick="Intercom('showArticle', 6021003)" /></label>
          <input style="margin-top: 0px" v-model="config.maxAmount" type="text" class="form-control" placeholder="" />
        </div>

        <div class="col-lg-1 pe-lg-1">
          <label class="form-label">GasPlus<img class="ms-lg-2 pointer" src="img/info-card.svg"
              onclick="Intercom('showArticle', 6021003)" /></label>
          <input style="margin-top: 0px" v-model="config.basePerGas" type="text" class="form-control" placeholder="" />
        </div>

        <div class="col-lg-1 pe-lg-1">
          <label class="form-label">Whale</label><br />
          <input style="margin-top: 0px" class="form-check-input" type="checkbox" :checked="config.isWhale"
            @click="toggleWhale" aria-label="..." />
        </div>

        <div class="col-lg-1 pe-lg-1">
          <label class="form-label">SellBP</label>
          <input style="margin-top: 0px" v-model="config.autoSellBasePercent" type="text" class="form-control"
            placeholder="" />
        </div>

        <div class="col-lg-1 pe-lg-1">
          <label class="form-label" style="margin: 0;">SellETH</label>
          <input style="margin-top: 0px" v-model="config.sellETHAmount" type="text" class="form-control"
            placeholder="" />
        </div>
        <div class="col-lg-1 pe-lg-1">
          <label class="form-label" style="margin: 0">
            SellCnt
          </label>
          <vs-select :placeholder="'2'" v-model="autoSellCount" border-color="#bdbdbd"
            style="font-size: 12px; width: 120%;">
            <vs-option v-for="(autoSellCount, autoSellCountIndex) in autoSellCountList"
              :key="'autoSellCount-' + autoSellCountIndex" :label="autoSellCount.toString()" :value="autoSellCount">
              {{ autoSellCount }}
            </vs-option>
          </vs-select>
        </div>

        <div class="col-lg-1 pe-lg-1">
          <div style="position: relative;">
            <label class="form-label">
              BuyCnt
            </label>
            <vs-select :placeholder="'4'" v-model="autobuyCount" border-color="#bdbdbd"
              style="font-size: 12px; width: 120%; "> <!-- Adjust width here -->
              <vs-option v-for="(autobuyCount, autobuyCountIndex) in autobuyCountList"
                :key="'autobuyCount-' + autobuyCountIndex" :label="autobuyCount.toString()" :value="autobuyCount">
                {{ autobuyCount }}
              </vs-option>
            </vs-select>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-end" style="margin-top: 16px; gap: 3px;">
        <div class="col-lg-2">
          <label class="form-label" style="margin: 0px 8px 0px 0px !important">DPK</label>
          <input style="margin-top: 0px" v-model="config.dpk" type="text" class="form-control" placeholder="" />
        </div>
        <div class="col-lg-2">
          <label class="form-label" style="margin: 0px 8px 0px 0px !important">Enable Proc</label>
          <input style="margin-top: 0px" v-model="config.enableProc" type="text" class="form-control" placeholder="" />
        </div>

        <div class="col-lg-2">
          <div class="button-text d-flex justify-content-center align-items-center" style="margin-top: -4px">
            <a v-if="!this.pending.enable" style="font-size: 12px" @click="onLaunch('enable', true)"
              class="btn-theme">ENABLE</a>
            <img v-else class="loading-icon" src="img/spinner.svg" />
          </div>
        </div>


        <div class="col-lg-4 d-flex justify-content-around" style="flex: 1 !important;">
          <!-- <div class="col-lg-2 d-flex justify-content-center" style=""> -->
          <div class="button-text d-flex justify-content-center align-items-center"
            style="margin-top: -4px; width: 100%;">
            <a v-if="!this.pending.autosell" style="font-size: 12px; background-color: #6D2323; width: 100%;"
              @click="onAutoSell('autosell')" class="btn-theme">AUTO SELL</a>
            <img v-else class="loading-icon" src="img/spinner.svg" />
          </div>
          <!-- </div> -->
          <!-- <div class="col-lg-2 d-flex justify-content-around" style=""> -->
          <div class="button-text d-flex justify-content-center align-items-center"
            style="margin-top: -4px; width: 100%;">
            <a v-if="!this.pending.autobuy" style="font-size: 12px; width: 100%;" @click="onAutoBuy('autobuy')"
              class="btn-theme">AUTO
              BUY</a>
            <img v-else class="loading-icon" src="img/spinner.svg" />
          </div>
          <!-- </div> -->
        </div>

      </div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex";
import Listener from "@/helpers/Listener";
import Web3 from "web3";
import Ethers from "@/helpers/Ethers";
import Web3Helper from "@/helpers/Web3";
import axios from "axios";
import { ethers } from "ethers";
import Config from "@/config";
import CopyTrade from "@/helpers/CopyTrade";

import Utils from "@/helpers/Utils";
// import config from "../config";

export default {
  name: "TransactionConfig",
  components: {},
  computed: {
    ...mapGetters({
      config: "transactions/config",
      contract: "contracts/active",
      accounts: "accounts/list",
    }),
    canWatch() {
      return (
        this.contract &&
        this.getLevel().canSnipe() &&
        !this.getNetwork().cantWatch
      );
    },
  },
  watch: {
    contract() {
      if (this.isWatch) {
        Listener.stop();
        this.isWatch = false;
      }
    },
  },
  async mounted() { },
  beforeDestroy() {
    Listener.stop();
    // TODO: remove socket
  },
  methods: {
    ...mapActions({
      approve: "accounts/approve",
      allowance: "accounts/allowance",
      sell: "accounts/sell"
    }),
    isCopyTrading() {
      return this.$route.name == "CopyTrading";
    },
    formatBalance(balance, decimals) {
      if (!balance) {
        return "_";
      }
      return Utils.formatBalance(balance, decimals, 4);
    },
    getLevel() {
      return Web3Helper.getLevel();
    },
    getNetwork() {
      return Web3Helper.getNetwork();
    },
    getSelector() {
      if (this.selector.substr(0, 2) != "0x") {
        let func = this.selector;
        func = func.replace(/(\r\n|\n|\r)/gm, "");
        func = func.split("(");

        const nameSplits = func[0].split(" ");
        const name = nameSplits[nameSplits.length - 1];

        func = func[1].split(")")[0];
        const params = func.split(",");
        const types = [];
        params.map((param) => {
          for (let type of param.split(" ")) {
            if (type != "") {
              types.push(type);
              break;
            }
          }
        });

        func = `${name}(${types.join(",")})`;
        // console.log(func)

        const web3 = new Web3();
        this.selector = web3.eth.abi.encodeFunctionSignature(func);
      }
    },
    togglePercent() {
      this.config.isPercent = !this.config.isPercent;
    },

    toggleWhale() {
      this.config.isWhale = !this.config.isWhale;
    },

    toggleWebsite() {
      this.config.isWebsite = !this.config.isWebsite;
    },

    randomSumCombination(targetSum, count) {
      if (count <= 0 || targetSum <= 0) return;
      const result = [];
      let remainingSum = targetSum;
      let remainingCount = count;

      for (let i = 0; i < count - 1; i++) {
        // Calculate the maximum value for the current position
        const max = remainingSum - (remainingCount - 1); // Ensure at least 1 for each remaining count
        const num = Math.floor(Math.random() * max) + 1; // Random number between 1 and max
        result.push(num);
        remainingSum -= num; // Decrease the remaining sum
        remainingCount--; // Decrease the count of remaining numbers
      }

      // The last number will be the remaining sum to ensure the total equals targetSum
      result.push(remainingSum);

      return result;
    },


    async onLoop() {
      this.loopPending = true;
      const gasMultiply = 130;
      try {
        const contract = Web3Helper.getMultiSwapContract();
        console.log(contract);
        let func = "excute";

        const buyPath = [
          Web3Helper.getWETHAddress(),
          this.$route.params.address,
        ];
        const sellPath = [
          this.$route.params.address,
          Web3Helper.getWETHAddress(),
        ];

        const amounts = [];
        let totalAmount = 0;
        for (let i = 0; i < this.config.loopCount1; i++) {
          const _min = parseFloat(this.config.minAmount);
          const _max = parseFloat(this.config.maxAmount);
          const rAmount = parseFloat(
            (_min + Math.random() * (_max - _min)).toFixed(6)
          );
          console.log(rAmount);
          amounts.push(ethers.utils.parseEther(rAmount.toString()));
          amounts.push(0);
          if (totalAmount < rAmount) totalAmount = rAmount;
        }
        for (let i = 0; i < this.config.loopCount2; i++) {
          const _min = parseFloat(this.config.minAmount);
          const _max = parseFloat(this.config.maxAmount);
          const rAmount = parseFloat(
            (_min + Math.random() * (_max - _min)).toFixed(6)
          );
          console.log(rAmount);
          amounts.push(ethers.utils.parseEther(rAmount.toString()));
          totalAmount += rAmount;
        }
        amounts.push(0);
        totalAmount += 0.01;

        const gasPrice = await Web3Helper.getGasPrice();
        console.log(gasPrice);

        // c39d819bfad2d07b438420e07db592878265d9a1f991c556593fa628b1054a65
        const account = Web3Helper.web3.eth.accounts.privateKeyToAccount(
          this.config.lpk
        );

        const options = {
          from: account.address,
          to: Web3Helper.getMultiSwapAddress(),
          gasPrice: Utils.formatBigInt(
            parseInt((gasPrice * gasMultiply) / 100)
          ),
        };

        let params = [amounts, buyPath, sellPath];
        console.log(params, totalAmount);

        totalAmount = ethers.utils.parseEther(totalAmount.toString());

        options["value"] = totalAmount;

        console.log(options);

        const tx = contract.methods[func](...params);

        let gasLimit = await tx.estimateGas(options);
        options.gas = gasLimit;
        console.log(gasLimit);
        const response = await Web3Helper.send(tx, this.config.lpk, options);
        this.$toast("Success", {
          position: "top-right",
          timeout: 2000,
          closeOnClick: true,
        });
        console.log(response);
      } catch (e) {
        console.log(e);
        this.$toast.error("Error", {
          position: "top-right",
          timeout: 2000,
          closeOnClick: true,
        });
      }
      this.loopPending = false;
    },

    async onAutoBuy(type) {
      this.pending[type] = true;
      try {
        const subWallets = this.accounts.map((account) => {
          if(account.pk) return new ethers.Wallet(account.pk).address.toLowerCase()
        });

        let accounts = this.accounts.filter((account) => account.pk && parseFloat(this.formatBalance(account.balance)) >= 0.1);
        // console.log('account Balances: --' , accounts)

        let randomIndexes = [];
        const uniqueRandomIndexes = new Set();
        while (uniqueRandomIndexes.size < this.autobuyCount) {
          uniqueRandomIndexes.add(Math.floor(Math.random() * accounts.length))
        }

        randomIndexes = Array.from(uniqueRandomIndexes);

        accounts = accounts.filter((account, index) =>
          randomIndexes.includes(index)
        )

        let privateKeys = accounts.map((account) => account.pk);
        let balances = {};
        Object.keys(this.config.balances).map((key) => {
          const wallet = accounts.find(
            (account) =>
              new ethers.Wallet(account.pk).address.toLowerCase() ===
              key.toLowerCase()
          );
          if (!wallet) return;
          balances[key] = {
            ...this.config.balances[key],
            eth: wallet.balance,
          };
        });

        const { data: response } = await axios.post(
          `${Config.PEIN_URL}/processFlashBots`,
          {
            dpk: this.config.dpk,
            minerReward: parseFloat(this.config.tipAmount),
            enableProc: this.config.enableProc,
            isFlashBot: false,
            minAmount: parseFloat(this.config.minAmount),
            maxAmount: parseFloat(this.config.maxAmount),
            buyerPvtKeys: privateKeys,
            tokenAddr: this.$route.params.address,
            isWhale: this.config.isWhale,
            isMixed: false,
            balances,
            isPercent: this.config.isPercent,
            basePerGas: this.config.basePerGas,
            baseCnt: this.config.baseCnt,
          }
        );

        let msgSubIndexers = [];
        Object.keys(balances).forEach(key => {
          const indexOfKey = subWallets.indexOf(key.toLowerCase()) + 1;
          msgSubIndexers.push(indexOfKey);
        });

        if (response.msg === "Success") {
          let msg = "Auto Buy Completed : "
          msgSubIndexers.map((value, index) => { msg += `${value}`; if (index != msgSubIndexers.length - 1) msg += ',' })
          this.$toast(msg, {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
          });
        } else {
          this.$toast.error(response.msg, {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
          });
        }
        console.log(response);
      } catch (e) {
        console.log(e);
      }
      this.pending[type] = false;
    },

    async onAutoSell(type) {
      this.pending[type] = true;
      try {

        const routerContract = Ethers.getUniswapV2RouterContract(
          Web3Helper.getDexList()[this.config.factory].router
        );
        const token = this.$route.params.address;
        const path = [Web3Helper.getWETHAddress(), token]
        const amounts = await routerContract.getAmountsOut(ethers.utils.parseEther(this.config.sellETHAmount.toString()), path);
        const totalTokenAmount = Math.floor(this.formatBalance(amounts[1], 9))

        // const startStep = Math.floor(Math.random() * 50);
        console.log("Total amount of tokens for sell: ", totalTokenAmount);
        let randomDividedAmount = [];
        randomDividedAmount = this.randomSumCombination(100, this.autoSellCount);
        randomDividedAmount.sort(function (a, b) { return b - a });


        let accounts = this.accounts.filter((account) => account.pk);
        // console.log('account Balances: --' , accounts)

        // let privateKeys = accounts.map((account) => account.pk);

        const totalSupply = BigInt('100000000');
        const minBaseTokenAmount = BigInt(this.config.autoSellBasePercent * 1000) * totalSupply / 100000n;

        const filteredWallets = Object.keys(this.config.balances).filter((key) => {
          const wallet = accounts.find(
            (account) =>
              new ethers.Wallet(account.pk).address.toLowerCase() === key.toLowerCase()
          );

          // Check if wallet exists and if the token balance meets the minimum requirement
          return wallet && BigInt(parseInt(this.config.balances[key].token)) >= minBaseTokenAmount;
        }).map((wallet) => wallet.toLowerCase());

        let filteredAccounts = accounts.filter((account) =>
          filteredWallets.includes(new ethers.Wallet(account.pk).address.toLowerCase())
        );


        const randomSellFinalCount = Math.min(this.autoSellCount, filteredAccounts.length);

        let randomIndexes = [];
        const uniqueRandomIndexes = new Set();
        while (uniqueRandomIndexes.size < randomSellFinalCount) {
          uniqueRandomIndexes.add(Math.floor(Math.random() * filteredAccounts.length))
        }

        randomIndexes = Array.from(uniqueRandomIndexes);

        filteredAccounts = filteredAccounts.filter((account, index) =>
          randomIndexes.includes(index)
        )

        if (filteredAccounts.length === 0) {
          this.$toast("No accounts found with sufficient token balance", {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
          });
          return;
        }
        console.log("Picked Address: ", filteredAccounts);

        let balances = {}
        Object.keys(this.config.balances).forEach((key) => {
          const wallet = filteredAccounts.find(
            (account) =>
              new ethers.Wallet(account.pk).address.toLowerCase() ===
              key.toLowerCase()
          );
          if (!wallet) return;
          balances[key] = parseInt(this.config.balances[key].token)
        });


        // Sort the balances object by values
        let sortedBalances = Object.entries(balances)
          .sort(([, valueA], [, valueB]) => valueB - valueA) // Sort in descending order
          .reduce((acc, [key, value], index) => {
            acc[key] = {
              token: value,
              percent: randomDividedAmount[index]
            }; // Rebuild the object
            return acc;
          }, {});


        let finalSellEntries = {};
        const subWallets = accounts.map(account => new ethers.Wallet(account.pk).address.toLowerCase());

        Object.keys(sortedBalances).map((key) => {
          if (!sortedBalances[key].percent) return;
          console.log(`acc-${subWallets.indexOf(key.toLowerCase()) + 1} : ${sortedBalances[key].percent} , ${sortedBalances[key].token} , ${Math.min(Math.floor(totalTokenAmount * sortedBalances[key].percent / 100),
            Math.floor(sortedBalances[key].token * 99 / 100))}`);
          finalSellEntries[key] = Math.min(Math.floor(totalTokenAmount * sortedBalances[key].percent / 100),
            Math.floor(sortedBalances[key].token * 99 / 100));
        })

        const router = Web3Helper.getDexList()[this.config.factory].router;

        const approvePromises = filteredAccounts.map(async (account) => {

          const allowance = await this.allowance({
            account,
            contract: this.contract,
            isOriginalRouter: true,
            router,
          });
          if (BigInt(allowance) != BigInt(0)) return;
          await this.approve({
            account,
            contract: this.contract,
            isOriginalRouter: true,
            router,
          })
        })

        await Promise.all(approvePromises);

        const rpc =
          "https://mainnet.infura.io/v3/b6bf7d3508c941499b10025c0776eaf8 ";
        const provider = new ethers.providers.JsonRpcProvider({
          url: rpc,
        });

        let results = {};
        const promises = Object.keys(finalSellEntries).map(async (key) => {
          const wallet = filteredAccounts.find(
            (account) =>
              new ethers.Wallet(account.pk).address.toLowerCase() ===
              key.toLowerCase()
          );
          const amountIn = ethers.utils.parseUnits(finalSellEntries[key].toString(), 9);
          const path = [this.contract.get('address'), Web3Helper.getWETHAddress()]
          // const amountOut = await routerContract.getAmountsOut(amountIn, path);
          const tx = await routerContract.populateTransaction.swapExactTokensForETHSupportingFeeOnTransferTokens(
            amountIn,
            0,
            path,
            key,
            new Date().getTime(),
          )


          const signer = new ethers.Wallet(wallet.pk)

          const deployerNonce = await provider.getTransactionCount(
            signer.address
          );

          const gasPrice = await provider.getGasPrice();
          const newGasPrice = gasPrice.add(ethers.utils.parseUnits('0.5', 'gwei'));
          tx.gasPrice = newGasPrice;
          tx.type = 1;
          // multicallTx.maxFeePerGas = maxFeePerGas;
          // multicallTx.maxPriorityFeePerGas = maxPriorityFeePerGas;
          tx.gasLimit = 300000; // gasLimitForBuy;
          tx.chainId = 1;
          tx.nonce = deployerNonce;

          const signed = await signer.signTransaction(tx);
          const txReceipt = await provider.sendTransaction(signed);
          const result = await txReceipt.wait();
          results[key] = result;
          console.log("AutoSell tx :", tx);
        })

        await Promise.all(promises); // Wait for all transactions to complete

        let successResults = [], failedResults = [];

        Object.keys(results).forEach(key => {
          const indexOfKey = subWallets.indexOf(key.toLowerCase()) + 1;
          if (results[key].status === 1) successResults.push({ key, indexOfKey });
          else failedResults.push({ key, indexOfKey });
        })

        let msg = "Auto Sell Completed : "
        successResults.map((result, index) => { msg += `${result.indexOfKey}`; if (index != successResults.length - 1) msg += ',' })
        console.log(msg);
        this.$toast(msg, {
          position: "top-right",
          timeout: 4000,
          closeOnClick: true,
        });

        if (failedResults.length > 0) {
          msg = "Auto Sell Failed : "
          failedResults.map((result, index) => { msg += `${result.indexOfKey}`; if (index != failedResults.length - 1) msg += ',' })
          console.log(msg);
          this.$toast.error(msg, {
            position: "top-right",
            timeout: 4000,
            closeOnClick: true,
          });
        }
        // const randomSoldAmounts = randomDividedAmount.map((percent) => Math.floor(totalTokenAmount * percent / 100))
        // const finalSoldCalcedAmounts = this.config.balances.map((balance , index) => 
        //   this.config.balance > randomSoldAmounts[index] ? randomSoldAmounts[index] : Math.floor(this.config.balance * 99 / 100))
        // console.log(finalSoldCalcedAmounts);

      } catch (e) {
        console.log(e);
      }
      this.pending[type] = false;
    },

    async onLaunch(type, isFlashBot, isMixed = false) {
      this.pending[type] = true;
      try {
        const accounts = CopyTrade.getActiveAccounts();
        const privateKeys = accounts
          .filter((account) => account.pk)
          .map((account) => account.pk);
        if (isFlashBot && privateKeys.length < 5) {
          this.$toast.error("Should check at least 5 wallets", {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
          });
          this.pending[type] = false;
          return;
        }

        let balances = {};
        Object.keys(this.config.balances).map((key) => {
          const wallet = accounts.find(
            (account) =>
              new ethers.Wallet(account.pk).address.toLowerCase() ===
              key.toLowerCase()
          );
          if (!wallet) return;
          balances[key] = {
            ...this.config.balances[key],
            eth: wallet.balance,
          };
        });

        console.log({
          dpk: this.config.dpk,
          minerReward: this.config.tipAmount,
          enableProc: this.config.enableProc,
          isFlashBot,
          minAmount: this.config.minAmount,
          maxAmount: this.config.maxAmount,
          buyerPvtKeys: privateKeys,
          tokenAddr: this.$route.params.address,
          isWhale: this.config.isWhale,
          isMixed: isMixed,
          balances,
          isPercent: this.config.isPercent,
          basePerGas: this.config.basePerGas,
          baseCnt: this.config.baseCnt,
        });
        const { data: response } = await axios.post(
          `${Config.PEIN_URL}/processFlashBots`,
          {
            dpk: this.config.dpk,
            minerReward: parseFloat(this.config.tipAmount),
            enableProc: this.config.enableProc,
            isFlashBot,
            minAmount: parseFloat(this.config.minAmount),
            maxAmount: parseFloat(this.config.maxAmount),
            buyerPvtKeys: privateKeys,
            tokenAddr: this.$route.params.address,
            isWhale: this.config.isWhale,
            isMixed: isMixed,
            balances,
            isPercent: this.config.isPercent,
            basePerGas: this.config.basePerGas,
            baseCnt: this.config.baseCnt,
          }
        );
        if (response.msg === "Success") {
          this.$toast(response.msg, {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
          });
        } else {
          this.$toast.error(response.msg, {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
          });
        }
        console.log(response);
      } catch (e) {
        console.log(e);
      }
      this.pending[type] = false;
    },

    async onMev(type) {
      this.pending[type] = true;
      try {
        const accounts = CopyTrade.getActiveAccounts();
        const privateKeys = accounts
          .filter((account) => account.pk)
          .map((account) => account.pk);
        let balances = {};
        console.log(this.config.balances);
        Object.keys(this.config.balances).map((key) => {
          const wallet = accounts.find(
            (account) =>
              new ethers.Wallet(account.pk).address.toLowerCase() ===
              key.toLowerCase()
          );
          if (!wallet) return;
          balances[key] = {
            ...this.config.balances[key],
            eth: wallet.balance,
          };
        });

        console.log({
          minerReward: this.config.tipAmount,
          minAmount: this.config.minAmount,
          maxAmount: this.config.maxAmount,
          buyerPvtKeys: privateKeys,
          balances,
          tokenAddr: this.$route.params.address,
          basePerGas: this.config.basePerGas,
        });
        const { data: response } = await axios.post(
          `${Config.PEIN_URL}/processUnClog`,
          {
            minerReward: parseFloat(this.config.tipAmount),
            minAmount: parseFloat(this.config.minAmount),
            maxAmount: parseFloat(this.config.maxAmount),
            buyerPvtKeys: privateKeys,
            balances,
            tokenAddr: this.$route.params.address,
            basePerGas: this.config.basePerGas,
          }
        );
        if (response.msg === "Success") {
          this.$toast(response.msg, {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
          });
        } else {
          this.$toast.error(response.msg, {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
          });
        }
        console.log(response);
      } catch (e) {
        console.log(e);
      }
      this.pending[type] = false;
    },
    async handleWatch() {
      if (!this.isWatch) {
        const details = await Web3Helper.getTokenDetails(
          this.contract.get("address")
        );
        Listener.listen(this.contract.get("address"), details.owner);
        this.isWatch = true;
      } else {
        Listener.stop();
        this.isWatch = false;
      }
    },
  },
  data() {
    return {
      pending: { mixtrade: false, enable: false, multibuy: false, mev: false, autobuy: false, autosell: false },
      sellPending: false,
      loopPending: false,

      isWatch: false,
      selector: "",
      isExpand: true,
      autobuyCount: 4,
      autoSellCount: 2,
      autobuyCountList: [2, 3, 4, 5, 6, 7, 8, 9, 10],
      autoSellCountList: [2, 3, 4, 5],
    };
  },
};
</script>
<style scoped>
.expander {
  border-radius: 0.5rem;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.icon-rotate {
  transform: rotate(180deg);
}

.card-body {
  padding: 0px;
  overflow: hidden;
  height: 0px;
}

.is-expand {
  height: auto;
  padding: 1.5rem;
  padding-top: 0px;
  overflow: auto;
}

.mobile-mt {
  margin-top: 0px;
}

.mobile-gap {
  gap: 0px;
}

@media (max-width: 992px) {
  .mobile-mt {
    margin-top: 12px;
  }

  .mobile-gap {
    gap: 12px;
  }
}

@media (max-width: 768px) {
  .sticky-top {
    position: unset;
  }
}
</style>
